import { useRouter } from 'next/router';
import React, { useLayoutEffect, VFC } from 'react';

import PageLoader from '@/components/shared/utillities/loaders/PageLoader';
import User from '@/domain/core/User';

const IndexPage: VFC = () => {
	const { push, query } = useRouter();

	useLayoutEffect(() => {
		const path = (query.redirectUrl as string) || User.getInitialRedirectPath();
		push(path).catch(console.error);
	}, []);

	return <PageLoader />;
};

export default IndexPage;
